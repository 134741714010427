import * as React from 'react';

function SvgDownload(props) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.497 13h8.377c.583 0 1.125-.105 1.626-.314.501-.21.938-.502 1.311-.878s.665-.815.874-1.317A4.17 4.17 0 0016 8.867c0-.594-.105-1.144-.315-1.65a4.013 4.013 0 00-2.205-2.202 4.156 4.156 0 00-1.639-.307c-.446-.887-1.05-1.56-1.814-2.02C9.263 2.23 8.37 2 7.35 2a5.076 5.076 0 00-3.394 1.273A5.078 5.078 0 002.831 4.7a5.302 5.302 0 00-.573 1.81A3.373 3.373 0 00.632 7.672C.211 8.222 0 8.876 0 9.637c0 .653.15 1.233.45 1.739a3.2 3.2 0 001.239 1.192c.525.288 1.128.432 1.808.432zm2.15-4.354a.5.5 0 01.707 0L7.5 9.793V5.5a.5.5 0 011 0v4.293l1.146-1.147a.5.5 0 01.708.708l-2 2a.5.5 0 01-.708 0l-2-2a.5.5 0 010-.708z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgDownload;
